<template>
  <div id="app" class="app">
    <div class="pc-mode">
      <pc></pc>
    </div>
    <div class="mobile-mode">
      <mobile></mobile>
    </div>
  </div>
</template>

<script>
import pc from '@/components/pc'
import mobile from '@/components/mobile'
export default {
  components: {
    pc,
    mobile
  },
}
</script>

<style lang="less">
@import '../src/assets/font/font.css';
body {
  overflow: auto !important;
  margin: 0;
}
* {
    font-family: 'PingFangSC-Semibold', 'PingFang SC', 'Microsoft YaHei';
    font-family: MiSans;
}
h3, p {
  margin: 0;
}
a {
  text-decoration: none;
}

#app {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  margin: 0;
  padding: 0;
  clear: both;
  overflow: hidden;
  background: #000;
}
.pc-mode {
  display: none;
}
.mobile-mode {
  display: none;
}
@media (max-width: 700px) {
  .pc-mode {
    display: none;
  }
  .mobile-mode {
    display: block;
  }
}

@media (min-width: 700px) {
  .pc-mode {
    display: block;
  }
  .mobile-mode {
    display: none;
  }
}

/* 整屏滚动样式 */
.fullPage {
  height: 100vh;
  overflow: hidden;
}
.fullPageContainer {
  width: 100%;
  height: 100vh;
  transition: all linear 0.5s;
}

.but-main {
  padding-top: 96px;
  display: flex;
}
button {
  width: 166px;
  height: 54px;
  line-height: 54px;
  box-shadow: 0px 7px 15px 0px rgba(27,144,255,0.15);
  border-radius: 8px;
  border: 1px solid rgba(255,255,255,0.65);
  display: inline-block;
  background: none;
  margin-right: 20px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  img {
    width: 24px;
    height: auto;
    padding-right: 10px;
    vertical-align: sub;
  }
}
</style>
