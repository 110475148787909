<template>
  <swiper :options="swiperOption" ref="mySwiper">
    <swiper-slide>
      <div class="screen1">
        <div class="banner">
          <img src="../assets/logo.png" alt="logo" class="logo">
          <div>
            <span class="name">时间大师</span>
            <span class="slogn">知时光，懂自己，成就自我</span>
          </div>
        </div>
        <div class="main">
          <span>助您规划时光</span>
          <span class="gradient">养成好习惯</span>
          <span class="small">实现高效自我提升</span>
          <div class="but-main">
            <a href="https://apps.apple.com/us/app/%E6%97%B6%E9%97%B4%E5%A4%A7%E5%B8%88-time-lordor/id6472255181">
              <p class="button">
                <img src="../assets/icon_apple@2x.png" alt="ios 下载">
                <span>ios下载</span>
              </p>
            </a>
            <a href="https://www.shijiandashi.com/app/release.apk">
              <p class="button">
                <img src="../assets/icon_and@2x.png" alt="安卓下载">
                <span>安卓下载</span>
              </p>
            </a>
          </div>
          <img src="../assets/img_01@2x.png" alt="规划时光" class="screen1-img">
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="screen2">
        <div class="main">
          <span class="title">简约操作<img src="../assets/xh_01@2x.png" class="number"></span>
          <span class="gradient">数据强大</span>
          <span class="show">时间大师界面简约，操作简单，但背后却蕴含强大的数据分析功能，让您深度了解自己的任务进展，实时了解您的坚持情况，结合全网对比，激励您更高效地完成计划。</span>
          <img src="../assets/img_p2_01_m@2x.png" alt="简约操作 数据强大" class="screen2-img">
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="screen3">
        <div class="main">
          <span class="title">卓越的<img src="../assets/xh_02@2x.png" class="number"></span>
          <span class="gradient">交互设计</span>
          <span class="show">我们致力于优化用户体验，为每个操作赋予仪式感。精美交互设计让您在打卡等行为中享受愉悦，轻松掌控时间。</span>
          <img src="../assets/img_p3_02@2x.png" alt="简约操作 数据强大" class="screen3-img">
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="screen4">
        <div class="main">
          <span class="title gradient">社交互动<img src="../assets/xh_03@2x.png" class="number"></span>
          <span class="show">与好友共同制定目标，通过分享成就相互激励。内置自律级别、全网达成率排名、丰富报表和徽章等激励措施，助您保持积极进步的动力。</span>
          <img src="../assets/img_p4_01@2x_m.png" alt="简约操作 数据强大" class="screen4-img">
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="screen5">
        <div class="main">
          <span class="title">数据反馈<img src="../assets/xh_04@2x.png" class="number"></span>
          <span class="gradient">与监督</span>
          <span class="show">时间大师通过丰富数据反馈、横向对比、日历提醒和推送提醒等功能，督促您继续坚持计划，助力持续提升自己。</span>
          <img src="../assets/img_p5_01@2x.png" alt="简约操作 数据强大" class="screen5-img">
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="screen6">
        <div class="main">
          <span class="title">沉浸体验<img src="../assets/xh_05@2x.png" class="number"></span>
          <span class="gradient">掌控时间</span>
          <span class="show">提供白噪音、锁定手机、桌面组件等个性化功能，让您在使用中享受更沉浸式体验，专注完成任务。</span>
          <img src="../assets/img_p6_02@2x.png" alt="简约操作 数据强大" class="screen6-img">
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="screen7">
        <div class="main">
          <span class="title special">数据<span class="gradient">云同步</span><img src="../assets/xh_06@2x.png" class="number"></span>
          <span class="show">同一账号支持多设备登录，实现数据云同步，随时随地获取最新的任务进展。</span>
          <img src="../assets/img_p7_01@2x.png" alt="简约操作 数据强大" class="screen7-img">
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="screen8">
        <div class="main">
          <img src="../assets/logo.png" class="logo">
          <span class="name">时间大师</span>
          <span class="slogn">知时光，懂自己，成就自我</span>
          <div class="but-main">
            <a href="https://apps.apple.com/us/app/%E6%97%B6%E9%97%B4%E5%A4%A7%E5%B8%88-time-lordor/id6472255181">
              <p class="button">
                <img src="../assets/icon_apple@2x.png" alt="ios 下载">
                <span>ios下载</span>
              </p>
            </a>
            <a href="https://www.shijiandashi.com/app/release.apk">
              <p class="button">
                <img src="../assets/icon_and@2x.png" alt="安卓下载">
                <span>安卓下载</span>
              </p>
            </a>
          </div>
          <img src="../assets/img_p8_01@2x.png" alt="简约操作 数据强大" class="screen8-img">
        </div>
        <div class="footer">
          <p>© 2023 时间大师 All rights reserved.</p>
          <p>京ICP备17023015号-7</p>
        </div>
      </div>
    </swiper-slide>
    <div class="swiper-pagination"  slot="pagination"></div>
  </swiper>
</template>

<script>
export default {
  data() {
    return {
      swiperOption: {
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 30,
        mousewheel: true,
        autoHeight: true,
        height: window.innerHeight,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.mobile-mode {
  background: #000;
  width: 100%;
  height: 100vh;
  overflow: auto;
  .screen1 {
    padding-top: 45px;
    width: 100%;
    height: calc(100vh - 54px);
    display: flex;
    align-items: center;
    justify-content: center;
    // position: relative;
    span {
      width: 100%;
      text-align: center;
      font-size: 32px;
      line-height: 32px;
      padding-bottom: 8px;
      color: rgba(255,255,255,0.65);
      display: block;
    }
    .gradient {
      background-image: -webkit-linear-gradient(left, #D7FF29, #ABFE05);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .small {
      font-size: 14px;
      padding-top: 8px;
    }
    .screen1-img {
      width: 316px;
      height: auto;
      margin: 0 auto;
      display: block;
    }
  }
  .but-main {
    padding-top: 24px;
    width: 212px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .button {
      width: 100px;
      height: 32px;
      box-shadow: 0px 4px 9px 0px rgba(27,144,255,0.15);
      border-radius: 5px;
      border: 1px solid rgba(255,255,255,0.65);
      line-height: 24px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 12px;
        color: #FFFFFF;
        display: inline-block;
        height: 12px;
        line-height: 12px;
        padding-bottom: 0;
        width: auto;
      }
    }
    img {
      width: 14px;
      padding-right: 5px;
    }
  }
  .banner {
    width: 100%;
    height: 30px;
    padding-top: 24px;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    .logo {
      width: 32px;
      height: auto;
      padding-left: 24px;
      padding-right: 8px;
    }
    span {
      display: block;
    }
    .name {
      font-size: 14px;
      line-height: 20px;
      height: 20px;
      color: #FFFFFF;
      text-align: left;
    }
    .slogn {
      font-size: 10px;
      color: #999999;
      line-height: 10px;
    }
  }
  .screen2, .screen3, .screen4, .screen5, .screen6, .screen7, .screen8 {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    span {
      width: 100%;
      text-align: center;
      font-size: 32px;
      line-height: 32px;
      padding-bottom: 8px;
      color: rgba(255,255,255,0.65);
      display: block;
    }
    .title {
      position: relative;
    }
    .number {
      position: absolute;
      top: -18px;
    }
    .number {
      width: 16px;
      height: auto;
    }
    .gradient {
      background-image: -webkit-linear-gradient(left, #D7FF29, #ABFE05);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .show {
      width: auto;
      font-size: 12px;
      padding: 24px 36px 67px;
      text-align: left;
      line-height: 18px;
    }
    .screen2-img {
      width: 100%;
      height: auto;
    }
    .screen3-img {
      width: 272px;
      height: auto;
    }
    .main {
      text-align: center;
    }
  }
  .screen3 {
    .show {
      padding: 24px 36px 90px;
    }
    .number {
      top: -6px;
    }
  }
  .screen4 {
    .screen4-img {
      width: 100%;
      height: auto;
    }
    .number {
      top: -18px;
    }
  }
  .screen5 {
    position: relative;
    display: block;
    height: calc(100vh - 80px);
    padding-top: 80px;
  }
  .screen5-img {
    width: 70%;
    height: auto;
    position: absolute;
    left: 50%;
    margin-left: -35%;
  }
  .screen6, .screen7, .screen8 {
    span {
      display: inline-block;
    }
    .show {
      padding: 24px 36px 58px;
    }
    .gradient {
      display: inline;
    }
  }
  .screen6-img, .screen7-img {
    width: 240px;
    height: auto;
  }
  .screen8 {
    position: relative;
    .name {
      color: #FFFFFF;
      font-size: 16px;
      line-height: 16px;
    }
    .slogn {
      font-size: 20px;
      color: #ABFE05;
      line-height: 20px;
    }
    // .but-main {
    //   padding-top: 24px;
    //   width: 212px;
    //   margin: 0 auto;
    // }
    // button {
    //   width: 99px;
    //   height: 32px;
    //   box-shadow: 0px 4px 9px 0px rgba(27,144,255,0.15);
    //   border-radius: 5px;
    //   border: 1px solid rgba(255,255,255,0.65);
    //   line-height: 32px;
    //   font-size: 12px;
    // }
    // img {
    //   width: 14px;
    //   padding-right: 5px;
    // }
    .logo {
      width: 42px;
      height: auto  ;
    }
    .screen8-img {
      width: 328px;
      height: auto;
      margin-left: -57px;
    }
    .footer {
      width: 100%;
      height: 48px;
      position: absolute;
      left: 0;
      bottom: 0;
      text-align: center;
      font-size: 8px;
      color: rgba(255,255,255,0.65);
      line-height: 12px;
    }
  }
}
/* 轮播图样式 */
/deep/.swiper-pagination-bullet {
  background: rgba(255, 255, 255,0.45);
}
/deep/.swiper-pagination-bullet-active {
  background: #ABFE05;
}
</style>
