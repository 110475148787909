<template>
  <div>
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide>
        <div class="screen1">
          <div class="banner">
            <div class="banner-main">
              <img src="../assets/logo.png">
              <span class="name">时间大师</span>
              <span class="line"></span>
              <span>知时光，懂自己，成就自我</span>
            </div>
          </div>
          <div class="main">
            <span>助您...</span>
            <span class="time">规划时光</span>
            <span class="slogn">养成好习惯</span>
            <span class="slogn2">实现高效自我提升</span>
            <div class="but-main">
              <a href="https://apps.apple.com/us/app/%E6%97%B6%E9%97%B4%E5%A4%A7%E5%B8%88-time-lordor/id6472255181">
                <button><img src="../assets/icon_apple@2x.png" alt="ios 下载">ios 下载</button>
              </a>
              <a href="https://www.shijiandashi.com/app/release.apk">
                <button><img src="../assets/icon_and@2x.png" alt="安卓下载">安卓下载</button>
              </a>
            </div>
            <img src="../assets/img_01@2x.png" alt="规划时光" class="screen1-img">
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="screen2">
          <div class="main">
            <img src="../assets/img_p2_01@2x.png" alt="简约操作 数据强大" class="screen2-img">
            <div class="center">
              <p class="slogn">
                <span>简约操作</span>
                <span style="color: #ABFE05;">数据强大</span>
                <img src="../assets/xh_01@2x.png">
              </p>
            <span class="show">时间大师界面简约，操作简单，但背后却蕴含强大的数据分析功能，让您深度了解自己的任务进展，实时了解您的坚持情况，结合全网对比，激励您更高效地完成计划。</span>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="screen3">
          <div class="main">
            <div class="center">
              <p class="slogn">
                <span>卓越的</span>
                <span style="color: #ABFE05;">交互设计</span>
                <img src="../assets/xh_02@2x.png">
              </p>
              <span class="show">我们致力于优化用户体验，为每个操作赋予仪式感。<br>精美交互设计让您在打卡等行为中享受愉悦，轻松掌控时间。</span>
            </div>
            <img src="../assets/img_p3_02@2x.png" alt="卓越的交互设计" class="screen3-img">
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="screen4">
          <div class="main">
            <img src="../assets/img_p4_01@2x.png" class="phone">
            <img src="../assets/img_p4_02@2x.png" class="banner">
            <div class="center">
              <p class="slogn">
                <span style="color: #ABFE05;">社交互动</span>
                <img src="../assets/xh_03@2x.png">
              </p>
              <span class="show">与好友共同制定目标，通过分享成就相互激励。内置自律级别、全网达成率排名、丰富报表和徽章等激励措施，助您保持积极进步的动力。</span>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="screen5">
          <div class="main">
            <div class="center">
              <p class="slogn">
                <span>数据反馈</span>
                <span style="color: #ABFE05;">与监督</span>
                <img src="../assets/xh_04@2x.png">
              </p>
              <span class="show">时间大师通过丰富数据反馈、横向对比、日历提醒和推送提醒等功能，督促您继续坚持计划，助力持续提升自己。</span>
            </div>
            <img src="../assets/img_p5_02@2x.png" class="screen5-img">
            <img src="../assets/img_p5_01@2x.png" class="screen5-img2">
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="screen6">
          <img src="../assets/img_p6_01@2x.png" class="screen6-back">
          <div class="main">
            <img src="../assets/img_p6_02@2x.png" class="screen6-img">
            <div class="center">
              <p class="slogn">
                <span>沉浸体验</span>
                <span style="color: #ABFE05;">掌控时间</span>
                <img src="../assets/xh_05@2x.png">
              </p>
              <span class="show">提供白噪音、锁定手机、桌面组件等个性化功能，让您在使用中享受更沉浸式体验，专注完成任务。</span>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="screen7">
          <div class="main">
            <div class="center">
              <p class="slogn">
                <span>数据<span style="color: #ABFE05;">云同步</span></span>
                <img src="../assets/xh_06@2x.png">
              </p>
              <span class="show">同一账号支持多设备登录，实现数据云同步，随时随地获取最新的任务进展。</span>
            </div>
            <img src="../assets/img_p7_01@2x.png" class="screen7-img">
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="screen8">
          <div class="main">
            <img src="../assets/img_p8_01@2x.png" class="screen8-img">
            <div class="center">
              <img src="../assets/logo.png" class="logo">
              <p class="name">时间大师</p>
              <p  class="slogn" style="color: #ABFE05;">知时光，懂自己，成就自我</p>
              <div class="but-main">
                <a href="https://apps.apple.com/us/app/%E6%97%B6%E9%97%B4%E5%A4%A7%E5%B8%88-time-lordor/id6472255181">
                  <button><img src="../assets/icon_apple@2x.png" alt="ios 下载">ios 下载</button>
                </a>
                <a href="https://www.shijiandashi.com/app/release.apk">
                  <button><img src="../assets/icon_and@2x.png" alt="安卓下载">安卓下载</button>
                </a>
              </div>
            </div>
          </div>
          <div class="footer">
            <p>© 2023 时间大师 All rights reserved.<br>京ICP备17023015号-7</p>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination"  slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  data () {
    return {
      swiperOption: {
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 30,
        mousewheel: true,
        autoHeight: true,
        height: window.innerHeight,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.pc-mode {
  min-width: 1200px;
  .banner {
    width: 100%;
    height: 104px;
    .banner-main {
      width: 1200px;
      height: 64px;
      margin: 0 auto;
      padding-top: 40px;
      line-height: 64px;
      display: flex;
      align-items: center;
      span {
        font-size: 20px;
        color: #999;
      }
    }
    .name {
      font-size: 24px;
      color: #fff;
      line-height: 64px;
      padding: 0 16px 0 12px;
      font-weight: 500;
    }
    .line {
      width: 1px;
      height: 16px;
      background: #D8D8D8;
      margin-right: 16px;
    }
  }
  .screen1 {
    width: 100%;
    height: 100vh;
    position: relative;
    background-image: url(../assets/img_02@2x.png);
    background-size: 510px auto;
    background-repeat: no-repeat;
    background-position: 0 0;
    .index-back {
      width: 510px;
      height: 0;
      position: absolute;
      left: 0;
      top: 0;
    }
    .swiper-main {
      width: 1200px;
      height: calc(100vh - 104px);
      min-height: 960px;
      overflow: hidden;
      margin: 0 auto;
    }
    .main {
      width: 1200px;
      margin: 0 auto;
      position: relative;
      span {
        color: #fff;
        opacity: .65;
        font-size: 32px;
        display: block;
        padding-top: 217px;
      }
      .time {
        font-size: 64px;
        padding-top: 40px;
      }
      .slogn {
        font-size: 64px;
        line-height: 64px;
        padding-top: 0;
        color: #ABFE05;
      }
      .slogn2 {
        padding-top: 40px;
      }
    }
    .screen1-img {
      position: absolute;
      width: 680px;
      height: auto;
      right: 0;
      top: 145px;
    }
  }
  .screen2, .screen3, .screen4, .screen5, .screen6, .screen7, .screen8 {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .main {
      width: 1200px;
      height: 100vh;
      margin: 0 auto;
      position: relative;
    }
  }
  .screen2 {
    .screen2-img {
      width: 764px;
      height: auto;
    }
    .center {
      width: 480px;
      padding-top: 357px;
      position: absolute;
      top: 0;
      left: 723px;
      font-size: 20px;
    }
    .center {
      color: #999;
    }
    .slogn {
      font-size: 64px;
      padding-bottom: 64px;
      position: relative;
      span {
        display: block;
      }
      img {
        width: 34px;
        height: auto;
        position: absolute;
        top: -10px;
        left: 268px;
      }
    }
    .show {
      width: 480px;
      line-height: 32px;
    }
  }
  .screen3 {
    .main {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .center {
      width: 540px;
      font-size: 20px;
      color: #999;
    }
    .screen3-img {
      width: 610px;
      height: auto;
      padding-top: 120px;
    }
    .slogn {
      font-size: 64px;
      color: rgba(255, 255, 255, 0.65);
      position: relative;
      padding-bottom: 64px;
      span {
        display: block;
      }
      img {
        width: 34px;
        height: auto;
        position: absolute;
        top: 10px;
        left: 219px;
      }
    }
    .show {
      line-height: 32px;
    }
  }
  .screen4 {
    .phone {
      width: 616px;
      height: auto;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }
    .banner {
      width: 1200px;
      height: auto;
      position: absolute;
      z-index: 1;
      left: 0;
      bottom: 150px;
    }
    .center {
      width: 480px;
      position: absolute;
      right: 0;
      top: 100px;
      color: #999;
      font-size: 20px;
    }
    .screen3-img {
      width: 610px;
      height: auto;
      padding-top: 120px;
    }
    .slogn {
      font-size: 64px;
      color: rgba(255, 255, 255, 0.65);
      position: relative;
      margin-bottom: 64px;
      img {
        width: 34px;
        height: auto;
        position: absolute;
        top: -10px;
        left: 268px;
      }
    }
    .show {
      width: 480px;
      line-height: 32px;
    }
  }

  .screen5 {
    .center {
      width: 460px;
      padding-top: 120px;
      font-size: 20px;
    }
    .slogn {
      font-size: 64px;
      color: rgba(255, 255, 255, 0.65);
      position: relative;
      padding-bottom: 64px;
      span {
        display: block;
      }
      img {
        width: 34px;
        height: auto;
        position: absolute;
        top: -20px;
        left: 255px;
      }
    }
    .show {
      width: 460px;
      line-height: 32px;
      color: rgba(255, 255, 255, 0.65);
      padding-top: 64px;
    }
    .screen5-img {
      width: 500px;
      height: auto;
      padding-top: 64px;
    }
    .screen5-img2 {
      position: absolute;
      width: 316px;
      height: auto;
      right: 0;
      top: 50%;
      margin-top: -412px;
    }
  }


  .screen6 {
    position: relative;
    .screen6-back {
      width: 427px;
      height: auto;
      position: absolute;
      left: 0;
      top: 0;
    }
    .main {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .center {
      width: 460px;
      font-size: 20px;
    }
    .slogn {
      font-size: 64px;
      color: rgba(255, 255, 255, 0.65);
      position: relative;
      padding-bottom: 64px;
      span {
        display: block;
      }
      img {
        width: 34px;
        height: auto;
        position: absolute;
        top: -20px;
        left: 255px;
      }
    }
    .show {
      width: 460px;
      line-height: 32px;
      color: rgba(255, 255, 255, 0.65);
      padding-top: 64px;
    }
    .screen6-img {
      width: 470px;
      height: auto;
      padding-left: 128px;
    }
  }

  .screen7 {
    position: relative;
    .main {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .center {
      width: 460px;
      font-size: 20px;
    }
    .slogn {
      font-size: 64px;
      color: rgba(255, 255, 255, 0.65);
      position: relative;
      padding-bottom: 64px;
      img {
        width: 34px;
        height: auto;
        position: absolute;
        top: -20px;
        left: 320px;
      }
    }
    .show {
      width: 460px;
      line-height: 32px;
      color: rgba(255, 255, 255, 0.65);
      padding-top: 64px;
    }
    .screen7-img {
      width: 316px;
      height: auto;
      padding-right: 140px;
    }
  }

  .screen8 {
    position: relative;
    .main {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .screen8-img {
      width: 694px;
      height: auto;
    }
    .center {
      width: 384px;
      /* font-size: 20px; */
      text-align: center;
    }
    .logo {
      width: 64px;
      height: auto;
    }
    .name {
      color: #fff;
      line-height: 24px;
      padding: 24px 0 48px;
      font-size: 24px;
    }
    .slogn {
      font-size: 32px;
    }
    .but-main {
      padding-top: 64px;
      display: flex;
    }
    button {
      width: 166px;
      height: 54px;
      line-height: 54px;
      box-shadow: 0px 7px 15px 0px rgba(27,144,255,0.15);
      border-radius: 8px;
      border: 1px solid rgba(255,255,255,0.65);
      display: inline-block;
      background: none;
      margin-right: 20px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      img {
        width: 24px;
        height: auto;
        padding-right: 10px;
        vertical-align: sub;
      }
    }
  }
  .footer {
    width: 100%;
    height: 112px;
    text-align: center;
    font-size: 14px;
    color: rgba(255,255,255,0.65);
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
/* 轮播图样式 */
/deep/.swiper-pagination-bullet {
  background: rgba(255, 255, 255,0.45);
}
/deep/.swiper-pagination-bullet-active {
  background: #ABFE05;
}
</style>
